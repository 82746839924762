body {
    margin: 0;
}

.page {
    background: white;
    z-index: 1;
    min-height: 100vh;
    padding: 4rem 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.loading-screen {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-header {
    color: #3D3D7A;
    font-size: 32px !important;
    text-align: center;
    font-weight: 600 !important;
}

.sign-form {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-evenly;
}


.back-button-icon {
    position: absolute;
    top: 50px;
}

.slider-title {
    font-size: 32px !important;
    color: #fff;
    font-weight: 600 !important;
    text-align: center;
}

.slider-content {
    font-size: 18px !important;
    color: #fff;
    text-align: center;
    font-weight: 300;
}

.slide-fade-in {
    opacity: 0;
    animation: fadeIn 0.5s ease-in-out forwards;
}

.gmail-login div[role="button"] {
    border-radius: 25px !important;
    border: 1px solid #213555 !important;
}

.gmail-login iframe {
    width: 100% !important;
}

.gmail-login iframe #container {
    width: 100% !important;
}

.auth-slider {
    height: 100%;
}

.auth-slider .swiper-pagination-bullet-active {
    background: #fff !important;
}

.auth-slider .swiper-pagination-bullets {
    bottom: 80px !important;
}

.auth-slider .swiper-pagination-bullet {
    background: rgba(255, 255, 255, 50%);
    margin: 0 10px !important;
}

.words {
    color: #707070;
    font-size: 14px;
    font-weight: normal;
    margin-bottom: 4px;
    text-align: center;
    cursor: pointer;
}

.active-word {
    color: #213555;
    font-size: 14px;
    font-weight: bold;
}

.tag {
    background: #909AAA !important;
    color: #fff !important;
    direction: rtl !important;
    padding: 18px !important;
    margin-bottom: 10px !important;
    margin-right: 10px !important;
    border-radius: 25px !important;
}

.enter-tag-detail {
    font-size: 14px;
    font-weight: 400;
    text-align: justify;
}

.tag-pagination nav ul {
    justify-content: center;
}

.tag-pagination nav ul li svg {
    background-color: unset !important;
    padding: 0 !important;
    min-width: unset !important;
}

.suggest-tag {
    background: #909AAA;
    color: #000 !important;
    direction: rtl;
    padding: 18px !important;
    border-radius: 25px !important;
    text-transform: capitalize;
    margin: 0 10px 10px 0 !important;
}

.suggest-tag span {
    font-size: 16px;
    font-weight: 400;
    padding-left: unset !important;
}

.selected-tag {
    background: #3D3D7A !important;
    color: #fff !important;
    direction: rtl !important;
    padding: 18px !important;
    margin-bottom: 10px !important;
    margin-right: 10px !important;
    border-radius: 25px !important;
    width: 100% !important;
}

.tag svg {
    margin-right: 5px !important;
    margin-left: -6px !important;
    color: #fff !important;

}

.suggest-tag svg {
    margin-left: -6px !important;
    color: #000 !important;
}

.selected-tag svg {
    margin-right: 5px !important;
    margin-left: -6px !important;
    color: #fff !important;
}

.sidebar {
    background: #3D3D7A;
    padding: 42px;
    width: 45px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    position: sticky;
    left: 0;
    top: 0;
}

.sidebar button {
    margin: 20px 0;
    min-width: unset;
}

.sidebar button svg {
    width: 24px;
    height: 24px;
}

.sidebar .active-link {
    display: flex;
    align-items: center;
}

.sidebar .active-link:after {
    content: "";
    display: block;
    height: 40px;
    width: 5px;
    background-color: #fff;
    border-radius: 10px;
    position: absolute;
    right: 10px;
}

.top-nav header {
    background-color: #3D3D7A;
    position: relative;
    z-index: 1030;
}

.top-nav {
    z-index: 10000;
}

.bottom-nav {
    background-color: #3D3D7A;
    z-index: 1030;
}

.bottom-nav-icon {
    display: flex;
}


.slider-style {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-evenly;
    scale: 0.8;
}

.select-tag {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.tag-box {
    border: 1px solid #E0E0E0;
    border-radius: 10px;
}

.tag-box .swiper-pagination-bullet-active {
    color: rgb(95, 95, 180);
}


.find-tag {
    display: flex;
    flex-direction: column;
    font-size: 15px;
    color: #213555;
    margin-top: 50px;
    margin-bottom: 50px;
}

.find-tag a {
    text-decoration: none;
}

.done-button, .next-button {
    display: flex;
    justify-content: center;
}

.enter-tag {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.logo-header img {
    width: 140px;
}

.app-box {
    background: #F7F7F7;
    background-size: cover;
    border-radius: 15px;
    padding: 10px;
    border: 1px solid #E8E8E8;
    height: 100%;
    margin: 10px 0;
    overflow-y: auto;
    position: relative;
}

.app-box-notification {
    background: #F7F7F7;
    background-size: cover;
    border-radius: 15px;
    padding: 10px;
    border: 1px solid #E8E8E8;
    height: 100%;
    overflow-y: auto;
    position: relative;
}

.app-title {
    color: #213553 !important;
    font-size: 20px !important;
    font-weight: 500 !important;
    font-family: "Segoe UI", sans-serif !important;
}

.mobile-container {
    height: 100%;
}

.edite-profile {
    background-color: #F7F7F7;
    padding: 0 17px;
    border-radius: 15px;
    margin: 85px 0 0 0 !important;
    height: calc(100vh - 100px);
    border: 1px solid #E8E8E8;
    overflow-y: auto;
    position: relative;
}

.edit-profile-header {
    color: #213555;
    font-size: 20px !important;
    font-weight: 500;
}

.edite-profile-inputs {
    height: 100%;
    display: grid;
}

.active {
    height: 4rem !important;
    width: 100% !important;
    position: fixed !important;
    top: 0px !important;
    transition: 0.3s linear !important;
    display: flex !important;
    justify-content: stretch !important;
    align-items: center !important;
    background-color: #FFFFFF !important;
    border-bottom: 1px solid rgba(0, 0, 0, .1) !important;
    z-index: 40 !important;
}

.hidden {
    height: 4rem !important;
    width: 100% !important;
    z-index: 40 !important;
    border-bottom: 1px solid rgba(0, 0, 0, .1) !important;
    box-shadow: 0 2px 5px -1px rgba(0, 0, 0, .08) !important;
    position: fixed !important;
    top: -80px !important;
    transition: 0.3s linear !important;
}

.auth-input {
    font-size: 17px !important;
    color: #213555 !important;
    margin: 9px 8px;
}

.closed-edite-profile {
    overflow: hidden;
    background-color: #F7F7F7;
    border-radius: 15px;
    margin: 85px 0 0 0 !important;
    border: 1px solid #E8E8E8;
    display: flex;
    justify-content: center;
    align-items: center;
}


.closed-section button {
    margin: 10px 0 0 -10px !important;
}

.closed-app-box {
    background: #F7F7F7;
    background-size: cover;
    border-radius: 15px;
    border: 1px solid #E8E8E8;
    margin: 10px 0;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.closed-app-box-notification {
    background: #F7F7F7;
    background-size: cover;
    border-radius: 15px;
    border: 1px solid #E8E8E8;
    height: 45vh;
    margin: 10px 0;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.edit-profile-bar {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.edit-profile-bar input[name="username"] {
    padding: 9px 11px !important;
}

.edit-profile-bar input {
    padding: 0 !important;
}


.about-input .MuiInputBase-root {
    border-radius: 15px !important;
    padding: 15px !important;
    overflow-y: auto;
}

.forget-password {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.swal2-title {
    font-family: "sans-serif" !important;
}

.swal2-html-container {
    font-family: "sans-serif" !important;
}

.swal2-confirm {
    background-color: #3D3D7A !important;
}

.app-logo {
    height: 100%;
    object-fit: fill;
}

.icon-position {
    position: relative;
}

.comment-emoji {
    position: absolute !important;
    top: -400px;
    right: 0;
}

.upload-post-emoji {
    position: absolute !important;
    top: -400px;
    right: 0;
}

*::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
    background-color: #F5F5F5 !important;
}

*::-webkit-scrollbar {
    width: 2px !important;
    background-color: #F5F5F5 !important;
}

*::-webkit-scrollbar-thumb {
    background-color: #3D3D7A;
    border-radius: 10px;
}

.notification-content {
    display: flex;
    flex-direction: column;
}

.notification-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 25px 0
}

.username {
    font-size: 14px !important;
    font-weight: 500;
    text-wrap: nowrap !important;
}

.time {
    color: #BDBDBD;
    font-size: 14px !important;
}

.user-title {
    display: flex;
    align-items: baseLine;
    color: #707070;
    flex-wrap: wrap;
}

.user-profile {
    display: none;
}

.post-image-notification img {
    border-radius: 14%;
}

.user-profile img {
    border-radius: 100%;
}

.close-notification svg:hover circle {
    fill: #3D3D7A;
    transition: 500ms;
}

.relation-item span {
    color: #31323E;
    font-size: 14px;
    font-weight: 700;
    text-transform: capitalize;
}

.relation-item .avatar-relation {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.relation-item .relation-icon {
    width: 24px;
    height: 24px;
}

.relation-item {
    transition: 500ms;
    padding: 10px 16px 12px 10px;
    border-radius: 35px;
    margin-bottom: 10px;
    width: 100%;
    justify-content: space-between !important;
}

.relation-item:hover {
    background: #E6E6EC;
    transition: 500ms;
}


.request-item {
    margin-bottom: 5px;
    transition: 500ms;
    padding: 10px 10px 12px 10px;
    border-radius: 35px;
}

.request-item:hover {
    background: #E6E6EC;
    transition: 500ms;
}

.request-item .avatar-request {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.request-item span {
    color: #31323E;
    font-size: 14px;
    font-weight: 400 !important;
}

.request-item button {
    padding: 0;
    display: flex;
    justify-content: end;
}

.request-number {
    position: absolute;
    color: #FFF;
    font-size: 12px;
    font-weight: 400;
    background: #E71376;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    text-align: center;
    top: 30px;
    right: 36px;
}

.success-match {
    color: #43C70A !important;
    font-size: 10px !important;
    font-weight: 700 !important;
    margin-left: 5px !important;
}

.warning-match {
    color: #E9A60E !important;
    font-size: 10px !important;
    font-weight: 700 !important;
    margin-left: 5px !important;
}

.error-match {
    color: #FE4D0A !important;
    font-size: 10px !important;
    font-weight: 700 !important;
    margin-left: 5px !important;
}

.audio-recorder {
    box-shadow: unset !important;
    background: unset !important;
    margin-right: 15px;
}

.Mui-checked {
    color: #E71376 !important;
}

.hashtag {
    color: #5B55FF
}


.app-box-profile {
    background: #F7F7F7;
    background-size: cover;
    border: 1px solid #E8E8E8;
    border-radius: 15px;
    padding: 0;
    height: calc(100vh - 100px);
    overflow-y: auto;
}

.profile-container {
    background-color: #FFF;
    border-radius: 15px;
    border: 1px solid #E8E8E8;
    padding: 20px;
    display: flex;
    flex-direction: column;
    position: relative;
}

.profile-container .more-button {
    align-self: end;
}

.profile-container .unlock-button {
    background: #C8C6D0;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    min-width: unset;
    position: absolute;
    top: 24px;
    right: 32px;
}

.profile-detail {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    margin: 60px 0 0 0 !important;
}

.profile-statistics-sm {
    flex-direction: column;
    align-items: center;
    display: none;
}

.profile-statistics-sm h5 {
    font-size: 64px;
    font-weight: 700;
    color: #213555;
}

.profile-statistics-sm h6 {
    font-size: 16px;
    font-weight: 700;
    color: #213555;
}

.profile-statistics-xs {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 15px 20px;
}

.profile-statistics-xs h5 {
    font-size: 30px;
    font-weight: 500;
    color: #213555;
}

.profile-statistics-xs h6 {
    font-size: 16px;
    font-weight: 500;
    color: #213555;
}

.profile-address {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #213555;

    font-size: 12px !important;
}

.profile-address svg {
    margin: 0 5px 0 0;
}

.detail-user-name {
    font-size: 20px !important;
    font-weight: 700 !important;
    display: flex;
    align-items: center;
    padding: 16px 0 8px 0;
    margin: 0 0 0 20px;
    color: #213555;
    justify-content: center;
}

.detail-user-name svg {
    margin: 0 0 0 5px;
}

.profile-biography {
    color: #707070;
    font-size: 12px;
    text-align: center;
    margin-top: 26px !important;
}

.profile-rating {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 37px 0;
}

.profile-rating button {
    background: #31323E;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    min-width: unset;
}

.profile-rating .send-relation-btn {
    position: absolute;
    left: 32px;
    bottom: 24px;
}

.profile-rating .info-btn {
    position: absolute;
    right: 32px;
    bottom: 24px;
}

.rating-count {
    font-size: 32px !important;
    font-weight: 700 !important;
    color: #213555;
    margin-top: 36px !important;
    text-align: center;
}

.lock-profile-dialog h2 {
    color: #31323E;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 27px;
    margin-top: 32px;
}

.lock-profile-dialog p {
    color: #3C4048;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 58px;
}

.lock-profile-dialog span {
    color: #213555;
    font-size: 20px;
    font-weight: 700;
}

.lock-profile-dialog button {
    background: #272727;
    color: #FFF;
    font-size: 20px;
    font-weight: 700;
    border-radius: 25px;
    width: 312px;
    margin: 95px auto 40px auto;
}

div[role="dialog"] {
    border-radius: 20px !important;
}

.profile-info-avatar {
    width: 118px;
    height: 118px;
}

.profile-info-avatar img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
}

.profile-info-dialog h3 {
    color: #213555;
    font-size: 64px;
    font-weight: 700;
}

.profile-info-dialog h4 {
    color: #213555;
    font-size: 20px;
    font-weight: 700;
}


.profile-info-dialog {
    margin-bottom: 26px;
}

.profile-info-dialog .MuiPaper-elevation24 {
    padding: 30px 0;
}

.profile-info-dialog span {
    color: #213555;
    font-size: 16px;
    font-weight: 700;
}

.profile-info-dialog .location-profile {
    margin-top: 17px;
}

.profile-info-dialog .location-profile span {
    color: #213555;
    font-size: 12px;
    font-weight: 400;
}

.profile-info-dialog .personal-information span {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 8px;
    color: #707070;
    font-size: 12px;
    font-weight: 400;
}

.profile-info-dialog .personal-information span::before {
    content: "";
    width: 8px;
    height: 8px;
    border-radius: 50%;
    display: block;
    background: #E71376;
    margin-right: 10px;
}

.profile-info-dialog .about-profile-section p {
    color: #707070;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 41px;
}

.profile-info-dialog .about-profile-section h4 {
    margin-bottom: 15px;
}

.profile-info-tags {
    margin-top: 14px;
}

.profile-info-tags .colorful-tag {
    border-radius: 25px;
    padding: 10px 16px;
    margin-right: 10px;
    color: #000;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 10px;
}

.profile-info-dialog div[role="dialog"] {
    max-width: 648px !important;
}

.message-input-wrapper {
    display: flex;
    align-items: center;
    border-radius: 40px;
    margin: 0 20px;
}

.posts-container .MuiFormControl-root {
    justify-content: center;
}

.message-input-wrapper .MuiInputBase-root input {
    padding: 0 !important;
}

.message-input {
    width: 85%;
}

.message-input fieldset {
    border: unset;
}

.upload-post-slider .swiper-pagination-horizontal {
    bottom: 0 !important;
}

.Mui-checked + .MuiSwitch-track {
    background-color: #ff0047 !important;
}

.h-90vh {
    height: 90vh;
}

.chat-message-box > .MuiBox-root {
    height: 100%;
}

/* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.react-player video {
    object-fit: cover;
    border-radius: 15px;
}

.saved-messages .edite-profile {
    height: calc(100vh - 100px) !important;
}

.MuiTabs-scroller {
    overflow: auto !important;
}

.chatinput {
    width: 100%;
}

