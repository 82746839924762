.verify-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.verify-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.verify-box img[alt="verify-page"]{
    max-width: 100%;
    height: 100%;
    width: 500px;
}

.verify {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}